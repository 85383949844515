// Library imports
import { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { AES, pad, mode, enc } from "crypto-js";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
// Component imports
import Intl from "src/components/common/Intl";
import GlobalModal from "src/components/GlobalModal";
import SignupForm from "src/components/Auth/Signup/SignupForm";
// Config imports
import config from "src/config/config";
import { login, register, sendOtp } from "src/config/api";
import { useLazyQuery } from "@apollo/client";
// Images imports
import CloseIcon from "src/images/close.png";
// other imports
import { withAppContext } from "src/store/initAppContext";
import { BackButton } from "src/components/Auth/commonStyles";
import { theme } from "styled-tools";
import { GET_USERINFO } from "src/graphql/member";
import util from "src/util/util";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import WhatsappIco from "src/components/WhatsappIco";
import { gtag, install } from 'ga-gtag';
import {SRTM} from 'src/util/sportradarEvent';
import SEOContents from "src/components/SEOContents";
import { CRMOTPBtnClick, CRMPageVisit, CRMRegistrationSuccess, getInitialVisitorID } from "src/util/crmUtil";
import PixelsTrigger from "src/components/common/PixelsTrigger";
import React from "react";
import FomoSponsorsSlider from "../FomoSponsorsSlider";

// import pixelData from 'src/components/pixeldata.json'



export const pixelHandler = (marketingSource, isprev, isLead, isPurchase, brandID) => {
  if (marketingSource && brandID) {
    fetch('https://pixel.raksahb.com/pixeldata/pixeldata.json')
      .then(res => res.json())
      .then(pixelData => {
        try {
          const brandBasedData = (pixelData[brandID] || {})[marketingSource];
          if (brandBasedData) {
            const { pixel_id, is_preview, is_lead, is_purchase } = brandBasedData;

            import("react-facebook-pixel").then((x) => x.default)
              .then((ReactPixel) => {
                ReactPixel.init(pixel_id, null, {
                  autoConfig: true,
                  debug: false,
                });

                if (isprev && is_preview) {
                  ReactPixel.pageView();
                }
                else if (isLead && is_lead) {
                  ReactPixel.track("Lead", "");
                }
                else if (isPurchase && is_purchase) {
                  const txt = typeof is_purchase === 'boolean' ? 'Purchase' : is_purchase;
                  ReactPixel.track(txt, "");
                }
              })
              .catch(err => console.log('Could not fire pixel', err));
          }
        } catch (error) {
          console.log('Something went wrong', error);
        }
      })
      .catch(error => console.log('Problem in fetching data!', error));

  }
}

//modified the function for optimization
export const TriggerPxlParticular = (marketingSource, isprev = false, isLead = false, isPurchase = false, isSuccessSignup = false, brandID = 31) => {
  // Defined pixelIDs as an object that maps each marketingSource value to an array of corresponding Facebook pixel IDs
  const pixelIDs = {
    channel203: ['1312062079326762','360551062578831'],
    channel375: ['1312062079326762','360551062578831'],
    channel383: ['6228595167156824', '565841711862854', '3116237892001382', '559918119156358','655131589744817', '576245764353651' ],
    channel384: ['692482194588201', '1186337202244808', '3416787735225478', '1210930372886453', '660462219412181', '1407099313147840'],
    channel431:['943855719978506', '718324413027063', '589093289923908', '1145026749508970', '652585046676391', '689754076168851','782671236545743','1356627561569370','9910527989375170','243509991356075','3368717580046360','1585544248585480','660462219412181','506220518387250','1407099313147840','692482194588201','1009538736358297','3410525592602103'],
  };
  // Added a check to see if the marketingSource value is valid and if the brandID value is 31 or 32. If either condition is not met, the function returns early.

  if ((marketingSource && marketingSource.toLowerCase() === 'channel431' && brandID===31)) {
    import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      pixelIDs['channel431'].forEach((pixelID) => {
        ReactPixel.init(pixelID, null, { autoConfig: true, debug: false });
      });
      if (isprev) {
        ReactPixel.pageView();
      } else if (isLead) {
        ReactPixel.track("Lead", "");
      } else if (isPurchase) {
          ReactPixel.track("Purchase", "");
      } else if (isSuccessSignup) {
        ReactPixel.track("CompleteRegistration", "");
      }
    });
  }
  
   else if (!marketingSource || !(brandID === 31 || brandID === 32) || !pixelIDs[marketingSource.toLowerCase()]) {
    return;
  }   

  import("react-facebook-pixel")
    .then((x) => x.default)
    .then((ReactPixel) => {
      // I used Array.prototype.forEach() to loop through the array of pixel IDs for the given marketingSource value and call ReactPixel.init() on each one.
      pixelIDs[marketingSource.toLowerCase()].forEach((pixelID) => {
        ReactPixel.init(pixelID, null, { autoConfig: true, debug: false });
      });
      if (isprev) {
        ReactPixel.pageView();
      } else if (isLead) {
        ReactPixel.track("Lead", "");
      } else if (isPurchase) {
          ReactPixel.track("Purchase", "");
      } else if (isSuccessSignup) {
        ReactPixel.track("CompleteRegistration", "");
      }
    });
};


export const shareChatPixel = (marketingSource, brandID) => {
  if (marketingSource && marketingSource.toLowerCase() === "channel1035") {
    if (brandID === 33 || brandID === 34) {
      let w: any = window;
      let d = document;
      let s = "script";
      let l = "scLayer";
      w["scSdkId"] = "9JSrNF_eUN";
      w[l] = w[l] || [];
      w.scq = function (eventName, eventType, p) {
        var props = p || {};
        w[l].push({
          eventName: eventName,
          eventType: eventType,
          meta: props,
          eventFireTs: Date.now(),
        });
      };
      w.scq('Complete registration', 'pre_defined', { pageUrl: w.location.href });
      let scr: any = d.createElement(s);
      scr.type = "text/javascript";
      scr.async = true;
      scr.src = "https://sc-events-sdk.sharechat.com/web-sdk.js";
      var x = d.getElementsByTagName(s)[0];
      x.parentNode.insertBefore(scr, x);
    }
  }
}

export const TriggerPxl = (
  marketingSource,
  isprev = false,
  isLead = false,
  isPurchase = false,
  brandID = 31
) => {
  if (marketingSource) {
      //FB sharechat
      if (marketingSource === "channel161") {
        let w: any = window;
        let d = document;
        let s = "script";
        let l = "scLayer";

        w["scSdkId"] = "h1FC39g_gQ";
        w[l] = w[l] || [];
        w.scq = function (eventName, eventType, p) {
          var props = p || {};
          w[l].push({
            eventName: eventName,
            eventType: eventType,
            meta: props,
            eventFireTs: Date.now(),
          });
        };

        if (isprev) {
          w.scq("PAGE_VIEW", "AUTO", { pageUrl: w.location.href });
        }

        let scr: any = d.createElement(s);
        scr.type = "text/javascript";
        scr.async = true;
        scr.src = "https://sc-events-sdk.sharechat.com/web-sdk.js";
        var x = d.getElementsByTagName(s)[0];
        x.parentNode.insertBefore(scr, x);

        if (isLead) {
          w.scq("Lead", "pre_defined");
        } else if (isPurchase) {
          w.scq("Purchase", "pre_defined");
        }
      }
      //sharechat for channel422
      else if (marketingSource === "channel422") {
        let w: any = window;
        let d = document;
        let s = "script";
        let l = "scLayer";

        w["scSdkId"] = "jmU2wffWZF";
        w[l] = w[l] || [];
        w.scq = function (eventName, eventType, p) {
          var props = p || {};
          w[l].push({
            eventName: eventName,
            eventType: eventType,
            meta: props,
            eventFireTs: Date.now(),
          });
        };

        if (isprev) {
          w.scq("PAGE_VIEW", "AUTO", { pageUrl: w.location.href });
        }

        let scr: any = d.createElement(s);
        scr.type = "text/javascript";
        scr.async = true;
        scr.src = "https://sc-events-sdk.sharechat.com/web-sdk.js";
        var x = d.getElementsByTagName(s)[0];
        x.parentNode.insertBefore(scr, x);

        if (isLead) {
          w.scq("Complete registration", "pre_defined");
        } else if (isPurchase) {
          w.scq("Purchase", "pre_defined");
        }
      }
      //FB snapchat
      else if (marketingSource === "channel162" && isLead) {
        const e: any = window;
        const t = document;
        const n = "https://sc-static.net/scevent.min.js";

        if (e.snaptr) return;

        let a: any = (e.snaptr = function () {
          a.handleRequest
            ? a.handleRequest.apply(a, arguments)
            : a.queue.push(arguments);
        });
        a.queue = [];
        var s = "script";
        let r: any = t.createElement(s);
        r.async = !0;
        r.src = n;
        let u = t.getElementsByTagName(s)[0];
        u.parentNode.insertBefore(r, u);

        e.snaptr("init", "031dcb3d-85b4-459f-9616-1d4a63d2d201", {});
        e.snaptr("track", "SIGN_UP");
      }
      else if (marketingSource === "channel198") {
        const e: any = window;
        e._tfa = e._tfa || [];
        if (isprev) {
          e._tfa.push({ notify: 'event', name: 'page_view', id: 1165104 });
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !function (t: HTMLScriptElement, f: HTMLScriptElement, a: string, x: string): any {
          if (!document.getElementById(x)) {
            t.async = true;
            t.src = a;
            t.id = x;
            f.parentNode.insertBefore(t, f);
          }
        }(document.createElement('script'), document.getElementsByTagName('script')[0], '//cdn.taboola.com/libtrc/unip/1165104/tfa.js', 'tb_tfa_script');

        if (isLead) {
          e._tfa.push({ notify: 'event', name: 'leadyolo', id: 1165104 });
        }
      }
      
      else if (marketingSource === "channel335" && (isLead || isprev)) {
        (function () {
          var d = document, w:any = window;
          w.MgSensorData = w.MgSensorData || [];
          w.MgSensorData.push({
            cid: 745340,
            lng: "us",
            project: "a.mgid.com"
          });
          var l = "a.mgid.com";
          var n = d.getElementsByTagName("script")[0];
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          var dt = !Date.now ? new Date().valueOf() : Date.now();
          s.src = "https://" + l + "/mgsensor.js?d=" + dt;
          n.parentNode.insertBefore(s, n);
        })();
      }   

      else if (marketingSource === "channel336" && isLead) {
        const _window: any = window;
        _window.esk('track', 'Conversion');
      }
      else if (marketingSource === "channel722") {
        (function () {
          var _document = document, _window: any = window;
          var ZEM_TAG_ID = '63444';
          if (_window.zemApi) {
            var toArray = function (object) {
              return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
            };
            _window.zemApi.marketerId = toArray(_window.zemApi.marketerId).concat(toArray(ZEM_TAG_ID));
            return;
          }

          var api: any = _window.zemApi = function () {
            api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
          };
          api.version = '1.0';
          api.loaded = true;
          api.marketerId = ZEM_TAG_ID;
          api.queue = [];
          var tag = _document.createElement('script');
          tag.async = true;
          tag.src = '//js-tag.zemanta.com/zcpt.js';
          tag.type = 'text/javascript';
          var script = _document.getElementsByTagName('script')[0];
          script.parentNode.insertBefore(tag, script);

          if (isprev) {
            _window.zemApi('track', 'PAGE_VIEW');
          }

          if (isLead) {
            _window.zemApi('track', 'Submit');
          }

        })();
      }   
      else {
        pixelHandler(marketingSource, isprev, isLead, isPurchase, brandID)
      }
  }
};

const signupSigmentPxl = (marketingSource:any, view = true) => {
  if (marketingSource && marketingSource.toLowerCase() === "channel377") {
  let d = document;
  let s = "script";
  let _src = view ? "https://secure.adnxs.com/seg?add=32940182&t=1" : "https://secure.adnxs.com/px?id=1662252&seg=32940179&t=1"
  let scr: any = d.createElement(s);
  scr.type = "text/javascript";
  scr.async = true;
  scr.src = _src;
  let x = d.getElementsByTagName(s)[0];
  x.parentNode.insertBefore(scr, x);
  }
}

const addGTag = (marketingSource: any, brandID, url: any = '') => {
  var callback = function () {
    if (url) {
      window.location = url;
    }
  };

  if (marketingSource && marketingSource.toLowerCase() === "channel344" && brandID === 31) {
    gtag('event', 'conversion', {
      'send_to': 'AW-11093940381/U66WCP6p95AYEJ2xgKop',
      'event_callback': callback
    });
  }
  else if (marketingSource && marketingSource.toLowerCase() === "channel379" && brandID === 31) {
    gtag('event', 'conversion', {
      'send_to': 'AW-11022762299/3Zi8CKGejpIYELuCiIgp',
      'event_callback': callback
    });
  }
}

// OutBrain API setup
const setupOutBrainApi = () => {
  var OB_ADV_ID = '00f16b21173f3b490435bbacd7bdf827cc';
  var obApi = (window as any).obApi || ((window as any).obApi = function() {
    obApi.dispatch ? obApi.dispatch.apply(obApi, arguments) : obApi.queue.push(arguments);
  });
  obApi.version = '1.1';
  obApi.loaded = true;
  obApi.marketerId = OB_ADV_ID;
  obApi.queue = [];
  
  var tag = document.createElement('script');
  tag.async = true;
  tag.src = '//amplify.outbrain.com/cp/obtp.js';
  tag.type = 'text/javascript';
  var script = document.getElementsByTagName('script')[0];
  script.parentNode.insertBefore(tag, script);

  return obApi; // Return initialized obApi
};

export const trackOutBrainEvent = (eventType: string) => {
  const obApi = setupOutBrainApi();
  if ((window as any).obApi) {
     obApi('track', eventType);
  }
};

export const taboolaPxl = (
  marketingSource: string,
  isprev = false,
  isReg = false,
  isPurchase = false,
) => {
  if (marketingSource && marketingSource.toLowerCase() === 'channel14') {
      const e: any = window;
      e._tfa = e._tfa || [];

      if (isprev) {
          e._tfa.push({ notify: 'event', name: 'page_view', id: 1693194 });
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !function (t: HTMLScriptElement, f: any, a: string, x: string): any {
          if (!document.getElementById(x)) {
              t.async = true;
              t.src = a;
              t.id = x;
              f.parentNode.insertBefore(t, f);
          }
      }(document.createElement('script'), document.getElementsByTagName('script')[0], '//cdn.taboola.com/libtrc/unip/1693194/tfa.js', 'tb_tfa_script');

      if (isReg) {
          e._tfa.push({ notify: 'event', name: 'complete_registration', id: 1693194 });
      }
      else if (isPurchase) {
          e._tfa.push({ notify: 'event', name: 'Deposit', id: 1693194 });
      }
  }
}


interface SignupProps {
  app: {
    setState: any;
    handleLogin: boolean;
    handleSignup: boolean;
    showTimer: boolean;
    showResend: boolean;
    brandId: any
  };
  theme: any;
  brandId: any;
}

export type UserOptions = {
  userName: string;
  phoneNumber: string;
  password: string;
  otpCode: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  referrerCode?: string;
  brandId: any;
};

function SignUp(props: SignupProps) {
  const {
    app: {brandId},
    theme,
  } = props;

  const { config: { showsignup = true, showFomoSponsorSlider } } = theme;
  const navigate = useNavigate();
  const signupQuery = useParams();
  const TOKEN_SECRET_KEY = config.secretKey;
  const {
    app: { setState, handleSignup, handleLogin, showTimer, showResend },
  } = props;
  const [page, setPage] = useState(0);
  const [otpLoader, setOtpLoader] = useState(false);
  const [loading, setLoader] = useState(false);
  const [isModalOpen, setModal] = useState(true);
  const [otpHandle, setOtpHandle] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [networkMessage, setNetworkMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [searchParams] = useSearchParams();
  const marketingSource = searchParams.get("marketingSource") || "";
  const clickeventID = searchParams.get("clickid") || "";

  const [token, setToken] = useState(null);

  const [getUserInfo] = useLazyQuery(GET_USERINFO, {
    fetchPolicy: "network-only",
    onError: (error) => {
      setErrorMessage(error.message);
    },
    onCompleted: (data) => {
      handleSuccess(data);
    },
  });

  const [userData, setUserData] = useState({
    first_time_login: false,
    first_time_deposit: false,
  });

  //adding search param hook
  // const {search} = useLocation();

  const triggerModal = (value: boolean) => {
    setModal(value);
  };

  useEffect(() => {
    if (handleSignup) {
      setModal(true);
    }

    //adding for FB pixel
    TriggerPxl(marketingSource, true, false, false, brandId);

    if (marketingSource && (marketingSource.toLowerCase() === "channel383" || marketingSource.toLowerCase() === "channel384" || marketingSource.toLowerCase() === "channel431")) {
      TriggerPxlParticular(marketingSource, true, false, false, false, brandId)
    }


    return () => {
      setState({ handleLogin: false });
    };
  }, [handleSignup]);


  useEffect(() => {
    if (marketingSource || clickeventID) {
      sessionStorage.setItem("sessionMarketSource", marketingSource);
      sessionStorage.setItem("clickid", clickeventID);
    }

    if(!showsignup) {
      navigate("/Login")
    }

    // for Sigment Pixel 
    if(brandId === 31){
      signupSigmentPxl(marketingSource, true);
      //for Google Pixel
      if (marketingSource && marketingSource.toLowerCase() === "channel344"){ install('AW-11093940381');}
      if (marketingSource && marketingSource.toLowerCase() === "channel379"){ install('AW-11022762299');}

      if (marketingSource && (marketingSource.toLowerCase() === "channel639" || marketingSource.toLowerCase() === "channel175")){
          // Track out brain page view
          trackOutBrainEvent('PAGE_VIEW');
      }
    }

    if(brandId === 39){
       //Taboola Pixel Code 
       taboolaPxl(marketingSource,true);
    }

    //for sportradar Init
    SRTM.init(brandId);
    //for CRM
    CRMPageVisit();
  }, []);

  const handleSuccess = (data) => {
    const payload = util.getValue(data, ["member", "userInfo"], {});
    if (payload.length !== 0) {
      // cookieUtil.remove(ECookieName.COOKIE_TOKEN);
      cookieUtil.set(ECookieName.COOKIE_USERNAME, payload.username, 30);
      cookieUtil.set(ECookieName.COOKIE_MEMBERID, payload.memberId, 30);
      cookieUtil.set(
        ECookieName.COOKIE_ORIGINAL_MEMBERID,
        payload.memberId,
        30
      );

      cookieUtil.set(
        ECookieName.COOKIE_ORDER_LIMIT,
        JSON.stringify(payload.orderLimit),
        30
      );

      cookieUtil.set(ECookieName.COOKIE_LANG, "en-us", 30);
      cookieUtil.set(
        ECookieName.COOKIE_EXCHANGE_RATE,
        payload.exchangeRate,
        30
      );

      cookieUtil.set(ECookieName.COOKIE_ACCOUNT_TYPE, "CLIENT");
      cookieUtil.set(
        ECookieName.COOKIE_IS_SUBACCOUNT,
        payload.isSubAccount ? "1" : ""
      );
      cookieUtil.set(
        ECookieName.COOKIE_TOKEN,
        token ? token : localStorage.getItem(ECookieName.COOKIE_TOKEN)
      );
      cookieUtil.set(
        ECookieName.COOKIE_ACCOCIATIONS,
        JSON.stringify(payload.associations || [])
      );

      cookieUtil.set(
        ECookieName.COOKIE_USERINFO,
        JSON.stringify(payload || {})
      );

      cookieUtil.set(
        ECookieName.COOKIE_BFPT_INFO,
        JSON.stringify(
          payload.betfairInfo.map(
            (_: { betfairPt: number; categoryId: number }) => ({
              bfPt: _.betfairPt,
              categoryId: _.categoryId,
            })
          )
        ),
        30
      );
      cookieUtil.set(
        ECookieName.COOKIE_BRAND_ID,
        payload.brandId ? payload.brandId : 31
      );

      CRMRegistrationSuccess(brandId, payload.username, payload.memberId);
      //console.log('stateFTD',JSON.stringify(userData));
      navigate("/", {
        state: {
          first: userData.first_time_login,
          fd: userData.first_time_deposit,
        },
      });
      window.location.reload();
    }
  };
  
  const onSubmit = (formData, event) => {
    const {
      app: { setState, brandId },
    } = props;
    event.preventDefault();
    event.stopPropagation();
    setLoader(true);
    const {
      userName,
      phoneNumber,
      password,
      otpCode,
      firstName,
      lastName,
      email,
      referrerCode,
    }: UserOptions = formData;
    let nextData = {
      userName,
      email,
      phoneNumber,
      password,
      otpCode,
      firstName,
      lastName,
      phoneCountry: "in",
      referrerCode,
      marketingSource: sessionStorage.getItem("sessionMarketSource") || "",
      brandId: brandId,
      visitorId:getInitialVisitorID(), // adding for CRM ref
      clickid:sessionStorage.getItem("clickid") || "",
      // pageNumber: "1",
    };

    const info = JSON.stringify(nextData);
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();
    let data = {
      registerInfo: authInfo,
    };

    register(data)
      .then((response) => {
        let res = response.data;
        setLoader(false);
        setIsSuccess(true);
        setNetworkMessage("You have registered Successfully");
        setToken(res.token);
        setTimeout(() => {
          if (typeof setState === "function") {
            let _reqData = {
              handleLogin: true,
              handleSignup: false,
            };
            setState(_reqData);
            setIsSuccess(false);
            setOtpHandle(false);
            setNetworkMessage("");
            setUserData({
              first_time_login: res.is_first_time_login,
              first_time_deposit: res.is_first_time_deposit,
            });
            
        const utcDateString = Date.now();
        const date = new Date(utcDateString);
        const isoString = date.toISOString();
        if(sessionStorage){
          sessionStorage.setItem('userCreatedsign', isoString)
        }
            res.token
              ? getUserInfo({
                  variables: {
                    input: {
                      token: res.token,
                    },
                  },
                })
              : navigate("/Login");
          }
        }, 3000);

        // for Sigment Pixel 
        if (brandId === 31) {
          signupSigmentPxl(marketingSource, false);
        }
        TriggerPxl(marketingSource, false, true, false, brandId); // lead true after signup
        shareChatPixel(marketingSource, brandId) /// snapchat pixel for otp LEAD
        addGTag(marketingSource, brandId) // for Google Tag Manager
        
        if (marketingSource && marketingSource.toLowerCase() === "channel384" || marketingSource.toLowerCase() === "channel431"){
          TriggerPxlParticular(marketingSource, false, false, false, true, brandId)
        }

        if (marketingSource && (marketingSource.toLowerCase() === "channel639" || marketingSource.toLowerCase() === "channel175") && brandId === 31){
            // Track out brain page view
            trackOutBrainEvent('Submit');
        }

        if(brandId === 39){
          //Taboola Pixel Code 
          taboolaPxl(marketingSource,false, true);
       }

        //sport radar 
        SRTM.regFinishEvent(brandId,userName)
      })
      .catch((error) => {
        let errorResponse = error.response.data;
        setNetworkMessage(errorResponse.error);
        setLoader(false);
       // setOtpStatus(false);
        setIsSuccess(false);
      });

      //Sprtradar Reg
      SRTM.regStartEvent(brandId);
  };

  const sendOTP = async (event: React.MouseEvent<HTMLElement>, formData) => {
    if (typeof setState === "function") {
      let _reqData = {
        showResend: false,
      };
      setState(_reqData);
    }
    //setOtpHandle(true);
    event.preventDefault();
    event.stopPropagation();
    setOtpLoader(true);
    setOtpStatus(true);
    const formvalue = formData();
    const info = JSON.stringify({
      phoneNumber: formvalue.phoneNumber,
      phoneCountry: "in",
      brandId:brandId
    });
    const encryptedTarget = enc.Utf8.parse(info);
    const encryptSecretKey = enc.Utf8.parse(TOKEN_SECRET_KEY);
    const authInfo = AES.encrypt(encryptedTarget, encryptSecretKey, {
      iv: enc.Utf8.parse(""),
      padding: pad.Pkcs7,
      mode: mode.CBC,
    }).ciphertext.toString();
    let data = {
      registerInfo: authInfo,
    };
    sendOtp(data)
      .then((response) => {
       setOtpHandle(true);
       // setOtpStatus(true);
        setOtpLoader(false);
        setNetworkMessage('');

        TriggerPxlParticular(marketingSource, false, false, false, true, brandId);
        // if (typeof setState === "function") {
        //   let _reqData = {
        //     showTimer: true,
        //   };
        //   setState(_reqData);
        // }
        // setTimeout(() => {
        //   if (typeof setState === "function") {
        //     let _reqData = {
        //       showTimer: false,
        //       showResend: true,
        //     };
        //     setState(_reqData);
        //   }
         
        // }, 75000);

      })
      .catch((error) => {
        let errorResponse = error.response?.data;
        setOtpHandle(false);
        //setOtpStatus(false);
         setIsSuccess(false);
        setNetworkMessage(
          errorResponse?.success === false && errorResponse?.error
        );
        setOtpLoader(false);
      })
      .finally(()=> {
        if (typeof setState === "function") {
          let _reqData = {
            showTimer: true,
          };
          setState(_reqData);
        }
        setTimeout(() => {
          if (typeof setState === "function") {
            let _reqData = {
              showTimer: false,
              showResend: true,
            };
            setState(_reqData);
          }
         
        }, 75000);

      })

      //CRM
      CRMOTPBtnClick(formvalue.phoneNumber,brandId,formvalue.userName)
  };

  // const closeTootltip = () => {
  //   setTooltipState(false);
  // }

  //adding one more variable for tracking event
  const showTracICUB = (marketingSource ==='channel1102' || marketingSource ==='channel1105' || marketingSource ==='channel1106')  && brandId === 33;
  const showMomentus = (marketingSource ==='channel10' || marketingSource ==='channel11')  && brandId === 33;

  //pixel for Baazi Adda
  const showEskimiPixel = (marketingSource === 'channel59' || marketingSource === 'channel60') && brandId === 33;
  const showRichardsPixel = (marketingSource === 'channel61' || marketingSource === 'channel62') && brandId === 33;
  const showGreedyGamesPixel = (marketingSource === 'channel63' || marketingSource === 'channel64') && brandId === 33;
  
  const currentChannelId = marketingSource; // This directly sets the current channel based on marketingSource
  

  const pixelData = [
    { showPixel: showEskimiPixel, channelId: 'channel59', page: 'signup', events: ['PageView', 'Lead'] },
    { showPixel: showEskimiPixel, channelId: 'channel60', page: 'signup', events: ['PageView', 'Lead'] },
    { showPixel: showRichardsPixel, channelId: 'channel61', page: 'signup', events: ['PageView', 'Lead'] },
    { showPixel: showRichardsPixel, channelId: 'channel62', page: 'signup', events: ['PageView', 'Lead'] },
    { showPixel: showGreedyGamesPixel, channelId: 'channel63', page: 'signup', events: ['PageView', 'Lead'] },
    { showPixel: showGreedyGamesPixel, channelId: 'channel64', page: 'signup', events: ['PageView', 'Lead'] }
  ];

  
  return (
    <div>
      <GlobalModal
        isModalOpen={isModalOpen}
        closeModal={(value) => triggerModal(value)}
      >
        <SEOContents pageName="signup"/>
        <Wrapper className="showHideClassName">
          <SignUpContentWrapper>
            <SignUpBoxWrapper>
              <div className="form-container">
                <TextHeadVertical>
                  <Tittle>
                    <Intl langKey="SIGNUP@TITLE" />
                  </Tittle>
                </TextHeadVertical>

                <SignUpTopWrapper>
                  <div className="login-user">
                    <Link className="loginSignup_logo" to="/">
                    {brandId == 33 || brandId == 34 ? <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_betdaily}`}  className='betdaily' alt="Logo" /> : (brandId == 39 || brandId == 40) ? <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo_fomo}`}  className='betdaily' alt="" /> : <img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_logo}`} alt="Logo"  />}
                    </Link>
                    <p>
                      <Intl langKey="AUTH@EXISTINGUSER" />{" "}
                      <button onClick={() => navigate("/login")}>
                        <span>
                          <Intl langKey="AUTH@CLICKHERE" />
                        </span>
                      </button>
                    </p>
                  </div>
                </SignUpTopWrapper>

                {/** singup form Component injected here */}
                <SignupForm
                  signupQuery={signupQuery}
                  isSuccess={isSuccess}
                  onSubmitHandler={onSubmit}
                  onSendOtpHandler={sendOTP}
                  networkMessage={networkMessage}
                  setNetworkMessage={setNetworkMessage}
                  otpHandle={otpHandle}
                  otpStatus={otpStatus}
                  setOtpLoader={otpLoader}
                  loading={loading}
                  showTimer={showTimer}
                  showResend={showResend}
                />

                <button
                  className="close"
                  type="button"
                  onClick={() => navigate("/")}
                ></button>
                <BackButton>
                  <button
                    className="backbutton"
                    onClick={page === 1 ? () => setPage(page - 1) : null}
                    style={
                      page === 1 ? { display: "block" } : { display: "none" }
                    }
                  >
                    {page === 1 ? "BACK" : null}
                  </button>
                </BackButton>
              </div>
              <WhatsappIco rightSpace="100px"/>
            </SignUpBoxWrapper>
            {showFomoSponsorSlider && <RightBlockWrapper>
              <FomoSponsorsSlider />
            </RightBlockWrapper>}
          </SignUpContentWrapper>
        </Wrapper>
      </GlobalModal>
      {
        isSuccess && showTracICUB && (<TrackingImgWrp><img alt="" src='https://tracking.icubeswire.co/aff_a?offer_id=4536&goal_id=6899' width='1' height='1' id='pixelcodeurl' /></TrackingImgWrp>)
      }
      {
        isSuccess && showMomentus &&  (<TrackingImgWrp><img alt="" src='https://momentus.gotrackier.com/pixel?av=659559c38d7a8f4a0e4e5371' width='1' height='1' /></TrackingImgWrp>)
      }

      {pixelData.map(({ showPixel, channelId, page, events }) =>
          showPixel && (
            <React.Fragment key={channelId}>
              <PixelsTrigger channelId={currentChannelId} page={page} event={events[0]} />
              {isSuccess && <PixelsTrigger channelId={channelId} page={page} event={events[1]} />}
            </React.Fragment>
          )
       )}
    </div>
  );
}

export default withTheme(withAppContext(SignUp));

const SignUpContentWrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-image: url(${config.imageV3Host}/static/cashsite/${theme("image.AuthWrapBgNewIPL")});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;
  z-index: 99999;
  /* padding-left: 60px; */

  .display-block {
    display: block;
    z-index: 999;
  }

  .display-none {
    display: none;
  }

  .close {
    width: 45px;
    height: 45px;
    background-image: url(${CloseIcon});
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0%;
    top: 0;
    background-color: black;
    border-radius: 5px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const TextHeadVertical = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
 background: ${theme('palette.secondary-background-color-linear-new')};
  padding: 5px 15px;
  position: absolute;
  left: -5px;
  -ms-transform: rotate(20deg);
  transform: rotate(270deg);
  height: 25px;
`;

const Tittle = styled.div`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  top: -12px;
  color: #fff;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .form-container {
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, #121239 -3.33%, #151439 100%);
    border-radius: 10px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .login-user {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em 4em 1em 0;
      font-size: 12px;
      border-radius: 10px 10px 0 0;
      /* background-color: #020032; */
      /* background: #323232; */
      background: ${theme('palette.loginTopHeadWrapperBg')};

      p {
        color: white;
        margin: 0;
        font-size: 13px;

        span {
          color: #e7ab0f;
          cursor: pointer;
          font-weight: 700;
          font-size: 13px;
          text-decoration: underline;

          :hover {
            color: #b97f12;
          }
        }

        button {
          color: #e7ab0f;
          cursor: pointer;
          font-weight: 700;
          font-size: 13px;
          text-decoration: underline;
          border: none;
          background: transparent;
        }
      }

      .loginSignup_logo {
        width: 190px;
        display: flex;
        align-items: center;

        img {
          max-width: 100%;
        }
      }
    }

    .button {
      width: 100%;
      padding: 16px 10px;
      border-radius: 7px;
      color: #fff;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      position: relative;
      border: none;
      background: linear-gradient(180deg, #ba8c40 0%, #ba8c40 100%);

      // :hover {
      //   background: linear-gradient(180deg, #D0B99F 0%, #BBA58D 100%);
      // }
    }
    .button:disabled {
      background: linear-gradient(180deg, #d0b99f 0%, #bba58d 100%);
    }
  }

  .showHideClassName {
  }
`;

const SignUpBoxWrapper = styled.div`
  margin: 40px 0px 0px 40px;
`;

const SignUpTopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & img {
    width: 120px;
    padding: 0px 15px;
  }
`;

export const TrackingImgWrp = styled.div`
  position: absolute;
  top: -1100px;
  width: 1px;
  height: 1px;
`;

const RightBlockWrapper = styled.div`
  width: 26%;
  padding-left: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;